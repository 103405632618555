// @flow
import { IMPPDigitalTools, NoIndexMetaTag, SEO } from "@components";
import { Analytics, Footer, Navigation } from "@containers";
import { CategoryBarContext } from "@utils/Category/CategoryBarContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";
import { Container } from "reactstrap";

import { SlidingSections } from "../components";
import { contentfulTypenames, determineStyle } from "../utils";
import { LEAD_SCORING_DEFAULT_VALUES } from "../utils/Constants";
import { PageContext } from "../utils/DynamicArticles";
import * as style from "./style.module.scss";

const PageExperience = ({
    data: {
        page,
        channel,
        market,
        footer,
        navigation,
        site: {
            siteMetadata: { siteUrl },
        },
    },
    query,
    location,
}: *) => {
    const navData = {
        ...determineStyle(
            page?.navigationRendering,
            page.navigation || navigation,
        ),
        navigationTransparentOnScroll: page.navigationTransparentOnScroll,
    };

    return (
        <CategoryBarContext.Provider
            value={
                page.content?.length > 0 &&
                page.content.some(
                    entry =>
                        entry.__typename === contentfulTypenames.categoryBar,
                )
            }
        >
            <LocaleContext.Provider
                value={{
                    language: page.node_locale,
                    market,
                }}
            >
                <PageContext.Provider value={page}>
                    <Analytics.PageWrapper
                        key={page.id}
                        entry={page.id}
                        type={PAGE_TYPES.LANDING}
                        title={page.title}
                        language={page.node_locale}
                        urlParameters={query}
                        slug={page.slug}
                        segments={page.segments}
                        seoKeywords={page.seoKeywords}
                        leadScoringValue={
                            page.leadScoringValue ||
                            LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.LANDING]
                        }
                    >
                        <SEO
                            title={page.title}
                            translations={page.translations}
                            publishedMarkets={page.markets}
                            metaDescription={page.metaDescription}
                            siteUrl={siteUrl}
                            currentPath={location.pathname}
                            page={page}
                            market={market}
                        />
                        <NoIndexMetaTag page={page} channel={channel} />
                        <Navigation
                            translations={page.translations}
                            publishedMarkets={page.markets}
                            slug={page.slug}
                            data={navData}
                            hideCategoryBar={true}
                            hideCategoryBarMobile={true}
                        />
                        {page.topic === "Experience Page" && (
                            <IMPPDigitalTools
                                content={page.content}
                                country={market.code.toLowerCase()}
                                language={market.node_locale}
                            />
                        )}
                        {page.topic === "Sliding Sections Page" && (
                            <SlidingSections sectionData={page.content} />
                        )}
                        <Container className={style.wrapper}>
                            <Footer data={footer} />
                        </Container>
                    </Analytics.PageWrapper>
                </PageContext.Provider>
            </LocaleContext.Provider>
        </CategoryBarContext.Provider>
    );
};

export default withURLParameters(PageExperience);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPageExperiencePage(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            id
            contentful_id
            __typename
            title
            slug
            node_locale
            topic
            metaDescription
            seoKeywords
            navigationRendering
            navigationTransparentOnScroll
            leadScoringValue
            breadcrumb
            breadcrumbPath {
                ...BreadcrumbRecursive
            }
            translations {
                slug
                node_locale
            }
            markets {
                ...MarketGeneral
            }
            content {
                ...DisplaySection
                ...Content
                ...Podcast
                ...DisplayTabContainer
                ...DisplaySlidingSection
            }
            navigation {
                ...Navigation
            }
            displaySticky {
                ...DisplaySticky
            }
            footer {
                ...Footer
            }
            enableScrollTop
            updatedAt(formatString: "DD.MM.YYYY")
            segments
            noIndex
            hideBreadcrumbs
            disclaimer {
                ...Disclaimer
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }

        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;
